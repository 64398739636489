import React from "react";
import Frame6 from '../../assets/images/Frame6.png';
import Frame7 from '../../assets/images/Frame7.png';
import Frame8 from '../../assets/images/Frame8.png';
import classes from './HomeSecondComponent.module.css';
import SmallCard from "../SmallCard/SmallCard";



function HomeSecondComponent(){




    return(
        <div className={classes.secondPart}>
            <div className="container">
                <div className="inside">
                    <div className={classes.picturesDiv}>
                        <img className={classes.smallImage} src={Frame6} alt=""/>
                        <img className={classes.smallImage} src={Frame7} alt=""/>
                        <img className={classes.smallImage} src={Frame8} alt=""/>
                        <img className={classes.smallImage} src={Frame6} alt=""/>
                        <img className={classes.smallImage} src={Frame7} alt=""/>
                        <img className={classes.smallImage} src={Frame8} alt=""/>
                    </div>
                    <div className={classes.title}>Explore Our Packages</div>
                    <div className="container">
                        <SmallCard/>
                    </div>
                </div>
            </div>
        </div>


    )
}


export default HomeSecondComponent;


// const onOptionChange = (e) => {
//     setBody((prev) => ({
//         ...prev,
//         [e.target.name]: e.target.id,
//     }));
// };
//
//             <Radio
//                 label={"1 year"}
//                 name={"subscription_id"}
//                 value="1"
//                 id={id?.[0]?.id}
//                 onChange={onOptionChange}
//                 required={true}
//             />