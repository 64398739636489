import React, {useEffect} from "react";
import Frame1 from '../../assets/images/Frame1.png';
import Frame2 from '../../assets/images/Frame2.png';
import Frame3 from '../../assets/images/Frame3.png';
import Frame4 from '../../assets/images/Frame4.png';
import Frame5 from '../../assets/images/Frame5.png';
import BluePic from '../../assets/images/BluePic.png';
import classes from './Home.module.css';
import HomeFirstComponent from "../../components/HomeFirstComponent/HomeFirstComponent";
import HomeSecondComponent from "../../components/HomeSecondComponent/HomeSecondComponent";
import {Outlet,useNavigate} from 'react-router-dom'
import Heroes from "../Heroes/Heroes";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";




function Home (){


    const navigate = useNavigate()
    useEffect(()=>{
        navigate('/heroes');
    },[])

    return(
        <>
            <Header/>
            <div className={classes.homeStylying}>
                <div className={classes.title}>Let’s make</div>
                <div className={classes.bigTitle}>your hero character</div>
                <div className={classes.title}>together</div>
                {/*<div className={classes.backgroundBlue} style={{ 'background-image': `url(${BluePic})`}}>*/}
                    <div className="container">
                        <div className={classes.picturesDiv}>
                            <img className={classes.imageStyle} src={Frame1} alt=""/>
                            <div className={classes.first}>
                                <img className={classes.imageStyle} src={Frame2} alt=""/>
                            </div>
                            <div className={classes.second}>
                                <img className={classes.imageStyle} src={Frame3} alt=""/>
                            </div>
                            <div className={classes.third}>
                                <img className={classes.imageStyle} src={Frame4} alt=""/>
                            </div>
                            <img className={classes.imageStyle} src={Frame5} alt=""/>
                        </div>
                    </div>
                {/*</div>*/}
                <div className={classes.backgroundBlue} style={{ 'background-image': `url(${BluePic})`}}></div>
            </div>
            <HomeFirstComponent/>
            <HomeSecondComponent/>
            {/*<Heroes/>*/}
            <Outlet/>
            <Footer/>
        </>





    )
}


export default Home;