import React from "react";
import classes from './ReadTerms.module.css';
import {useNavigate} from "react-router-dom";
import Card from "../../components/Card/Card";
import BackVector from "../../assets/images/BackVector.png";




function ReadTerms() {

    const navigate = useNavigate();




    return (
        <div className="container">
            <div className="inside">
                <div className={classes.readTermsWhole}>
                    <div className="title">Terms and Conditions</div>
                    <div className={classes.subTitle}>Read our Terms & Conditions before payment</div>
                    <div className={classes.chooseTerms}>
                        <label className={classes.checkboxLabelTerms}>
                            <input
                                className={classes.checkboxStyleTerms}
                                type="checkbox"
                                // onChange={() => handleChange(item.done, index)}
                                // checked={item.done}
                                // id={index}
                            />I read terms & conditions and accept them
                        </label>
                    </div>
                </div>
                <div className="buttonsDiv">
                    <div className="backButton"
                         onClick={()=>navigate('/package')}><img className="backSign" src={BackVector} alt=""/> Back</div>
                    <div className="btnNext" onClick={()=>navigate('/activate-package')}>Next</div>
                </div>
            </div>
        </div>
    )
}

export default ReadTerms;