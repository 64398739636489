import React from "react";
import classes from './SmallCard.module.css';
import Button from "../../UI/Button/Button";


function SmallCard(){

    const cardInfo=[
        {
            type:"Basic - 10 Pics",
            amount:"$19"
        },
        {
            type:"Standart - 20 Pics",
            amount:"$19"
        },
        {
            type:"Max - 50 Pics",
            amount:"$19"
        }
    ]


    const renderCard=cardInfo.map((item, index)=>
        <div className={classes.cardStyle} key={index}>
            <div className={classes.cardType}>{item.type}</div>
            <div className={classes.cardAmount}>{item.amount}</div>
            {/*<Button>Buy</Button>*/}
        </div>
    )

    return(
        <div className={classes.cards}>
            {renderCard}
        </div>

    )
}


export default SmallCard;