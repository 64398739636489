import React, {useState, useEffect} from "react";
import useValidation from "../../hooks/useValidation";
import useInput from "../../hooks/useInput";
import classes from './ActivatePackage.module.css';
import Input from "../../UI/Input/Input";
import Visa from '../../assets/images/Visa.png';
import Master from '../../assets/images/Master.png';
import {useNavigate} from "react-router-dom";
import BackVector from "../../assets/images/BackVector.png";





function ActivatePackage (){

    const navigate = useNavigate();
    const {isNotEmpty, isEmail, isPhoneNumber, isCardNumber, isCardHolder, isExpDate, isCVV}=useValidation();
    const [activatePackageError, setActivatePackage] = useState(null);


    const {
        value: fullname,
        isValid: fullnameIsValid,
        hasError: fullnameHasError,
        valueChangeHandler: fullnameChangeHandler,
        inputBlurHandler: fullnameBlurHandler,
        reset: resetFullname,
        isTouched:fullnameIsTouched
    } = useInput(isNotEmpty);

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        isTouched:emailIsTouched
    } = useInput(isEmail);

    const {
        value: phoneNumber,
        isValid: phoneNumberIsValid,
        hasError: phoneNumberHasError,
        valueChangeHandler: phoneNumberChangeHandler,
        inputBlurHandler: phoneNumberBlurHandler,
        reset: resetPhoneNumber,
        isTouched:phoneNumberIsTouched
    } = useInput(isPhoneNumber);

    const {
        value: whatsappNumber,
        isValid: whatsappNumberIsValid,
        hasError: whatsappNumberHasError,
        valueChangeHandler: whatsappNumberChangeHandler,
        inputBlurHandler:whatsappNumberBlurHandler,
        reset: resetWhatsappNumber,
        isTouched:whatsappNumberIsTouched
    } = useInput(isNotEmpty);

    const {
        value: cardNumber,
        isValid: cardNumberIsValid,
        hasError: cardNumberHasError,
        valueChangeHandler:cardNumberChangeHandler,
        inputBlurHandler: cardNumberBlurHandler,
        reset: resetCardNumber,
        isTouched:cardNumberIsTouched
    } = useInput(isCardNumber);

    const {
        value: cardName,
        isValid: cardNameIsValid,
        hasError: cardNameHasError,
        valueChangeHandler:cardNameChangeHandler,
        inputBlurHandler:cardNameBlurHandler,
        reset: resetCardName,
        isTouched:cardNameIsTouched
    } = useInput(isCardHolder);

    const {
        value: expiration,
        isValid: expirationIsValid,
        hasError: expirationHasError,
        valueChangeHandler:expirationChangeHandler,
        inputBlurHandler: expirationBlurHandler,
        reset: resetExpiration,
        // isTouched:expirationIsTouched
    } = useInput(isExpDate);

    const {
        value: cvv,
        isValid: cvvIsValid,
        hasError: cvvHasError,
        valueChangeHandler:cvvChangeHandler,
        inputBlurHandler: cvvBlurHandler,
        reset: resetCVV,
        // isTouched:cvvIsTouched
    } = useInput(isCVV);



    let emailMessage = null;
    if (!email) {
        emailMessage = "Email is required";
    } else if (!emailIsValid) {
        emailMessage = "Invalid email";
    }


    let formIsValid = false;
    if (fullnameIsValid &&
        emailIsValid &&
        phoneNumberIsValid) {
        formIsValid = true;
    }

    useEffect(() => {

        if(fullnameIsTouched || emailIsTouched
            || phoneNumberIsTouched){
            setActivatePackage("")
        }
    },[fullnameIsTouched, emailIsTouched,
        phoneNumberIsTouched]);



    //
    //
    // const token = numberDigit1.concat(numberDigit2, numberDigit3, numberDigit4, numberDigit5, numberDigit6);
    // console.log(token)
    //
    //
    // let postVerifyEmail = async (token) => {
    //     let email=localStorage.getItem('email');
    //     let formData = new FormData();
    //     formData = {
    //         email: email,
    //         token: token,
    //         type: "verify"
    //     }
    //     console.log(formData, "formData");
    //     try {
    //         let response = await axios.post(`${config.baseUrl}api/verify/pin`, formData);
    //         console.log(response.data);
    //         if(response.data.success===true){
    //             await props.closeVerifyEmailModal();
    //             await props.openLoginModal()
    //         }
    //         // closeAndResetLoginModal();
    //         // props.setAccessToken(localStorage.getItem('accessToken'));
    //         // navigate(`/`);
    //     } catch (error) {
    //         setVerifyEmailError("Something went wrong");
    //     }
    // }
    //
    //
    // const submitHandler = async event => {
    //     event.preventDefault();
    //     if (!formIsValid) {
    //         return;
    //     }
    //     props.openLoginModal();
    //     await postVerifyEmail(token);
    //     await resetNumberDigit1();
    //     await resetNumberDigit2();
    //     await resetNumberDigit3();
    //     await resetNumberDigit4();
    //     await resetNumberDigit5();
    //     await resetNumberDigit6();
    // }
    //
    // const handleKeyPress = event => {
    //     if (event.key === 'Enter') {
    //         submitHandler()
    //     }
    // }
    //
    // function closeAndResetVerifyEmailModal () {
    //     props.closeVerifyEmailModal();
    //     resetNumberDigit1();
    //     resetNumberDigit2();
    //     resetNumberDigit3();
    //     resetNumberDigit4();
    //     resetNumberDigit5();
    //     resetNumberDigit6();
    //     setVerifyEmailError("")
    // }









    return(
        <div className="container">
            <div className="inside">
                <div className={classes.activatePackageWhole}>
                    <div className="title">Let’s start your journey</div>
                    <div className={classes.subTitle}>Let’s Activate your package</div>
                    <form className={classes.activatePackageForm}>
                        <div className={classes.paymentWhole}>
                            <div className={classes.paymentLeft}>
                                <div className={classes.paymentTitle}>Personal Details</div>
                                    <Input
                                        loginError={activatePackageError}
                                        hasError={fullnameHasError}
                                        label="Full Name"
                                        red=" *"
                                        errorText="Insert full name."
                                        maxWidth ='544px'
                                        input={{
                                            value: fullname,
                                            placeholder: "Type your full name",
                                            type: "text",
                                            onChange: fullnameChangeHandler,
                                            onBlur: fullnameBlurHandler
                                        }}
                                    />
                                    <Input
                                        loginError={activatePackageError}
                                        hasError={emailHasError}
                                        errorText={emailMessage}
                                        label="Email"
                                        red=" *"
                                        maxWidth ='544px'
                                        input={{
                                            value: email,
                                            placeholder: "Type your email",
                                            type: "email",
                                            onChange: emailChangeHandler,
                                            onBlur: emailBlurHandler
                                        }}
                                    />
                                <Input
                                    loginError={activatePackageError}
                                    hasError={phoneNumberHasError}
                                    errorText="Insert phone number."
                                    label="Phone Number"
                                    red=" *"
                                    maxWidth ='544px'
                                    input={{
                                        value: phoneNumber,
                                        placeholder: "Type your number",
                                        type: "number",
                                        onChange: phoneNumberChangeHandler,
                                        onBlur: phoneNumberBlurHandler
                                    }}
                                />
                                <Input
                                    loginError={activatePackageError}
                                    hasError={whatsappNumberHasError}
                                    errorText="Insert whatsapp number."
                                    label="Whatsapp number"
                                    maxWidth ='544px'
                                    input={{
                                        value: whatsappNumber,
                                        placeholder: "Type your Whatsapp number",
                                        type: "number",
                                        onChange: whatsappNumberChangeHandler,
                                        onBlur: whatsappNumberBlurHandler
                                    }}
                                />
                            </div>
                            <div className={classes.paymentRight}>
                                <div className={classes.paymentTitle}>Card Details</div>
                                <div className={classes.cards}>

                                    <label className={classes.radioLabelCards} >
                                        <input
                                            type="radio"
                                            // name={item.type}
                                            // value="1hero"
                                            // id={index}
                                            // checked={item.topping === "1hero"}
                                            // onChange={(e)=>onOptionChange(e,index)}
                                            // onChange={()=>handleChange(item.value,index)}
                                            className={classes.radioInputCards}
                                        /><img src={Visa} alt=""/>
                                    </label>
                                    <label className={classes.radioLabelCards}>
                                        <input
                                            type="radio"
                                            // name={item.type}
                                            // value="2hero"
                                            // id={index}
                                            // checked={item.topping === "2hero"}
                                            // onChange={(e)=>onOptionChange(e,index)}
                                            className={classes.radioInputCards}/><img src={Master} alt=""/>
                                    </label>
                                </div>
                                <Input
                                    loginError={activatePackageError}
                                    hasError={cardNumberHasError}
                                    errorText="Insert card number."
                                    label="Card Number"
                                    maxWidth ='544px'
                                    input={{
                                        value: cardNumber,
                                        placeholder: "Type your number in your card",
                                        type: "number",
                                        onChange: cardNumberChangeHandler,
                                        onBlur: cardNumberBlurHandler
                                    }}
                                />
                                <Input
                                    loginError={activatePackageError}
                                    hasError={cardNameHasError}
                                    errorText="Insert cardholder name."
                                    label="Name on Card"
                                    maxWidth ='544px'
                                    input={{
                                        value: cardName,
                                        placeholder: "Type the name in the card",
                                        type: "text",
                                        onChange: cardNameChangeHandler,
                                        onBlur: cardNameBlurHandler
                                    }}
                                />
                                <div className={classes.belowDiv}>
                                    <Input
                                        loginError={activatePackageError}
                                        hasError={expirationHasError}
                                        errorText="Insert expiration date."
                                        label="Expiration Date( MM / YY )"
                                        maxWidth ='260px'
                                        input={{
                                            value: expiration,
                                            placeholder: "Expiration Date( MM / YY )",
                                            type: "text",
                                            onChange: expirationChangeHandler,
                                            onBlur: expirationBlurHandler
                                        }}
                                    />
                                    <Input
                                        loginError={activatePackageError}
                                        hasError={cvvHasError}
                                        errorText="Insert CVV."
                                        label="Security Code"
                                        maxWidth ='260px'
                                        input={{
                                            value: cvv,
                                            placeholder: "Security Code",
                                            type: "number",
                                            onChange: cvvChangeHandler,
                                            onBlur: cvvBlurHandler
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="buttonsDiv">
                    <div className="backButton"
                         onClick={()=>navigate('/read-terms')}><img className="backSign" src={BackVector} alt=""/> Back</div>
                    <button disabled={!formIsValid} className="btnNext" onClick={()=>navigate('/payment-done')}>Next</button>
                </div>
            </div>
        </div>
    )
}


export default ActivatePackage;