import React, {useState} from "react";
import classes from './Card.module.css';
import Button from "../../UI/Button/Button";


function Card(){

    // const [topping, setTopping] = useState("1hero");


    const [cardInfo, setCardIndo] = useState([
        {
            type:"Basic - 10 Pics",
            amount:"$19",
            // topping:""
            // value:"1 hero"
        },
        {
            type:"Standart - 20 Pics",
            amount:"$19",
            // topping:""
            // value:"2 hero"
        },
        {
            type:"Max - 50 Pics",
            amount:"$19",
            // topping:""
            // value:"3 hero"
        }
    ])


    const onOptionChange = (e, index) => {
        let tmp = cardInfo[index];
        tmp.topping = e.target.value;
        console.log(tmp.topping, "tmp.topping")
        let cardInfoClone=[...cardInfo];
        cardInfo[index] = tmp;
        setCardIndo([...cardInfoClone])
        console.log(e, "e")
        console.log(index, "index")
    }



    const renderCard=cardInfo.map((item, index)=>
        <div className={classes.cardStyle} key={index}>
            <div className={classes.cardType}>{item.type}</div>
            <div className={classes.cardAmount}>{item.amount}</div>
            <div className={classes.radioInputs}>
                <label className={classes.radioLabel} >
                    <input
                        type="radio"
                        name={item.type}
                        value="1hero"
                        id={index}
                        checked={item.topping === "1hero"}
                        onChange={(e)=>onOptionChange(e,index)}
                        // onChange={()=>handleChange(item.value,index)}
                        className={classes.radioInput}
                    />Creating for 1 hero
                </label>
                <label className={classes.radioLabel}>
                    <input
                        type="radio"
                        name={item.type}
                        value="2hero"
                        id={index}
                        checked={item.topping === "2hero"}
                        onChange={(e)=>onOptionChange(e,index)}
                        className={classes.radioInput}/>Creating for 2 hero
                </label>
                <label className={classes.radioLabel}>
                    <input
                        type="radio"
                        name={item.type}
                        value="3hero"
                        id={index}
                        checked={item.topping === "3hero"}
                        onChange={(e)=>onOptionChange(e,index)}
                        className={classes.radioInput}
                    />Creating for 3 hero
                </label>
            </div>
            <Button>Buy</Button>
        </div>
    )

    return(
        <div className={classes.cards}>
            {renderCard}
        </div>

    )
}


export default Card;