import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import classes from './Heroes.module.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {Keyboard, Navigation, Pagination} from "swiper";
import Frame10 from "../../assets/images/Frame10.png";
import Frame11 from "../../assets/images/Frame11.png";
import Frame9 from "../../assets/images/Frame9.png";
import 'swiper/css';
import 'swiper/css/keyboard';
import "swiper/css/pagination";
import "swiper/css/navigation";


function Heroes(){

    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname);
    const {state} = useLocation();
    // const heroesPageState = state;
    const heroesPageState = state && state.heroesBackState;
    console.log(state?.heroesBackState, "heroesBackState")
    console.log(heroesPageState , "heroesPageState");





    const [homeSwpierInfo, setHomeSwiperIndo] = useState([
        {
            img: Frame10,
            name: "With Spiderman",
            done: false
        },
        {
            img: Frame11,
            name: "With Superman",
            done: false
        },
        {
            img: Frame9,
            name: "With Batman",
            done: false
        },
        {
            img: Frame10,
            name: "With Spiderman",
            done: false
        },
        {
            img: Frame11,
            name: "With Superman",
            done: false
        }
    ])

    let heroesPageIsValid =false;
    const even = (element) => element.done ===true;
    if(homeSwpierInfo.some(even)){
        heroesPageIsValid =true;
    }

    console.log(homeSwpierInfo, 'homeSwpierInfo')

    const handleChange = (done, index) => {
        let tmp = homeSwpierInfo[index];
        tmp.done = !done;
        let homeSwpierInfoClone = [...homeSwpierInfo];
        homeSwpierInfo[index] = tmp;
        setHomeSwiperIndo([...homeSwpierInfoClone]);
    };

    const handleChangeAll = (e) => {
        const newData = homeSwpierInfo.map(item => {
            return {...item, done: e.target.checked}
        })
        setHomeSwiperIndo(newData)
    }

    const renderHomePictures = homeSwpierInfo?.map((item, index) => (
        <SwiperSlide>
            <div className={classes.sliderInside} key={index}>
                <div className={classes.imgDiv}>
                    <img className={classes.imgImage} src={item.img}/>
                </div>
                <div className={classes.belowPart}>
                    <label className={classes.checkBoxLabel} htmlFor={index}>
                        <input
                            className={classes.checkboxStyle}
                            type="checkbox"
                            onChange={() => handleChange(item.done, index)}
                            checked={item.done}
                            id={index}
                        />
                    </label>
                    <div className={classes.name}>{item.name}</div>
                </div>
            </div>
        </SwiperSlide>
    ))

    return(
        <div className="container">
            <div className="inside">
                <div className="title">Let’s start your journey</div>
                <div className={classes.subTitle}>Choose the hero(es) you want to make your pictures with</div>
                <div className={classes.swiperDiv}>
                    {/*<HomeSwiper/>*/}
                    <div className="container">
                        <div className={classes.belowPart}>
                            <label className={classes.checkBoxLabel}>
                                <input
                                    className={classes.checkboxStyle}
                                    type="checkbox"
                                    onChange={handleChangeAll}
                                    defaultChecked={false}
                                />
                                Select all heroes
                            </label>
                        </div>
                        <Swiper
                            navigation={true}
                            modules={[Keyboard, Pagination, Navigation]}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            className={classes.max_width}
                            keyboard={{enabled: true}}
                            loop={true}
                            grabCursor={true}
                            slidesPerView={3}
                            spaceBetween={20}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                               1440: {
                                    slidesPerView: 3,
                                    // spaceBetween: 48,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    // spaceBetween: 22,
                                },
                                425: {
                                    slidesPerView: 1,
                                    // spaceBetween: 48,
                                },
                                320: {
                                    slidesPerView: 1,
                                    // spaceBetween: 48,
                                },
                            }}


                            // slidesPerView={3}
                            // onSlideChange={() => console.log('slide change')}
                            // onSwiper={(swiper) => console.log(swiper)}
                            // keyboard={{enabled: true}}
                            // modules={[Keyboard, Pagination, Navigation]}
                            // pagination={{
                            //     clickable: true
                            // }}
                            // navigation={true}
                            // breakpoints={{
                            //     1440:{
                            //         width:1440,
                            //         slidesPerView: 3,
                            //     },
                            //     425:{
                            //         width: 425,
                            //         slidesPerView: 1,
                            //     },
                            //     576: {
                            //         width: 576,
                            //         slidesPerView: 2,
                            //     },
                            //     768: {
                            //         width: 768,
                            //         slidesPerView: 2,
                            //     },
                            // }}
                        >
                            {renderHomePictures}
                        </Swiper>
                    </div>

                </div>
                <div className="buttonsDiv">
                    <div></div>

                    <button className="btnNext" disabled={!heroesPageIsValid}
                         onClick={()=> navigate('/upload', {state: [...homeSwpierInfo]})}>Next</button>
                </div>
            </div>
        </div>
    )
}



export default Heroes;


