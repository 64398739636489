import React from "react";
import classes from './HomeFirstComponent.module.css';
import Button from "../../UI/Button/Button";



function HomeFirstComponent(){
    return(
        <div className="container">
            <div className="inside">
                <div className={classes.title}>Become hero with us</div>
                <div className={classes.text}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</div>
                <Button>Try Now</Button>
            </div>
        </div>

    )
}


export default HomeFirstComponent;