import React from "react";
import Copy from '../../assets/images/Copy.png';
import classes from './PaymentDone.module.css';




function PaymentDone(){




    return(
        <div className="container">
            <div className="inside">
                <div className={classes.paymentDoneWhole}>
                    <div className="title">This is amazing</div>
                    <div className={classes.subTitle}>Your payment successfully done. This is your link with photos
                        which we sent in your accounts too. It will be active 20 days</div>
                    <div className={classes.inputDiv}>
                        <input className={classes.linkInput}
                            // value={inputValue}
                            // onChange={(event)=>setInputValue(event.target.value)}
                        />
                        <img className={classes.copyImg} src={Copy} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PaymentDone;