import React from "react";
import {useNavigate} from "react-router-dom";
import classes from './Header.module.css';



function Header(){

    const navigate = useNavigate();



    return(
        <div className={classes.headerWhole} onClick={()=>navigate('/heroes')}>Logo</div>
    )
}


export default Header;