import React, {useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import BackVector from '../../assets/images/BackVector.png';
import ImageUploading from 'react-images-uploading';
import classes from './Upload.module.css';


function Upload(){

    const navigate=useNavigate();
    console.log(window.location.pathname);
    const { state } = useLocation();
    const uploadPageState=state;
    console.log (uploadPageState, "uploadPageState")

    // const [images, setImages]=useState([]);
    const [images, setImages]=useState();
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    console.log(images, 'images')


    return(
        <div className="container">
            <div className="inside">
                <div className={classes.uploadWhole}>
                    <div className="title">Let’s start your journey</div>
                    {/*/!*<div className={classes.subTitle}>We suggest uploading between 10-15 images with different background.*!/</div>*/}
                    <div className={classes.subTitle}>Notice you should be the only one in the image and the images should
                        be a mix of portrait, profile and full body</div>
                    <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                          }) => (
                            <div
                                className={`upload__image-wrapper ${images!==undefined && "fullSize"} `}>
                                {!images &&
                                <button
                                    className={classes.uploadButton}
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    +
                                </button>}
                                &nbsp;
                                {/*<button onClick={onImageRemoveAll}>Remove all images</button>*/}
                                {imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                        <img className="uploadedImage" src={image['data_url']} alt="" width="100" />
                                        <div className="image-item__btn-wrapper">
                                            {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                            <button className="deleteButton" onClick={() => onImageRemove(index)}>X</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </ImageUploading>

                    {/*<div className={classes.uploader}>+</div>*/}
                    <div className={classes.allowed}>Allowed file types: png, jpg, jpeg.</div>
                </div>
                <div className="buttonsDiv">
                    {images ==undefined?
                        <div className="backButton"  onClick={()=>navigate('/heroes')}>
                            <img className="backSign" src={BackVector} alt=""/> Back</div>:
                        <div className="backButton" onClick={()=>navigate('/heroes', {state:{heroesBackState:[...images, ...uploadPageState]}})}>
                            <img className="backSign" src={BackVector} alt=""/> Back</div>}
                    <button className="btnNext" disabled={images===undefined} onClick={()=>navigate('/package', {state:[...images, ...uploadPageState]})}>Next</button>
                </div>
            </div>
        </div>
    )
}


export default Upload;