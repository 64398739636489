import React from "react";
import classes from './Footer.module.css';



function Footer(){
    return(
        <div className={classes.footerWhole}>Copyright © 2021 Webstie Name</div>
    )
}


export default Footer;