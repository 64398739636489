import React from "react";
import classes from './Package.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import Card from "../../components/Card/Card";
import BackVector from "../../assets/images/BackVector.png";



function Package() {

    const navigate = useNavigate();
    console.log(window.location.pathname);
    const { state } = useLocation();
    const packagePageState=state;
    console.log (packagePageState, "uploadPageState")



    return (
        <div className="container">
            <div className="inside">
                <div className={classes.packageWhole}>
                    <div className="title">Let’s start your journey</div>
                    <Card/>
                </div>
                <div className="buttonsDiv">
                    <div className="backButton"
                         onClick={()=>navigate('/upload')}><img className="backSign" src={BackVector} alt=""/> Back</div>
                    <button className="btnNext" onClick={()=>navigate('/read-terms')}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default Package;
