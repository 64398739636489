import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
    Navigate
} from "react-router-dom";
import Home from "./app/pages/Home/Home";
import Heroes from "./app/pages/Heroes/Heroes";
import Upload from "./app/pages/Upload/Upload";
import Package from "./app/pages/Package/Package";
import ReadTerms from "./app/pages/ReadTerms/ReadTerms";
import ActivatePackage from "./app/pages/ActivatePackage/ActivatePackage";
import './App.css';
import PaymentDone from "./app/pages/PaymentDone/PaymentDone";



function App() {

  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={ <Home/>}>
                  <Route index path="heroes" element={ <Heroes/>}/>
                  {/*<Route path="heroes" element={ <Heroes/>}/>*/}
                  <Route  path="upload" element={ <Upload/>}/>
                  <Route  path="package" element={ <Package/>}/>
                  <Route  path="read-terms" element={ <ReadTerms/>}/>
                  <Route path="activate-package" element={ <ActivatePackage/>}/>
                  <Route path="payment-done" element={ <PaymentDone/>}/>
                  <Route path="*" element={<Navigate to="/heroes" replace />} />
              </Route>
              <Route path="*" element={<Navigate to="/heroes" replace />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;

// <Route index element={<Home />} />
// <Route path="home" element={<Home />} >
//     <Route path="heroes" element={ <Heroes/>}/>
//     <Route  path="upload" element={ <Upload/>}/>
//     <Route  path="package" element={ <Package/>}/>
// </Route>
// <Route path="*" element={<Heroes/> } />